<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b example example-compact">
          <div class="card-header align-items-start">
            <div class="card-title">
              <h3 class="card-label">To'lov vedimost qoshish</h3>
            </div>
            <div class="card-element d-flex">
              <div class="checkbox-inline mt-3">
                <label class="checkbox checkbox-success">
                  <input
                    @click="source = !source"
                    :checked="source"
                    id="asd"
                    type="checkbox"
                    name="Checkboxes5"
                  />
                  <span></span>
                  Bank
                </label>
                <label class="checkbox checkbox-success">
                  <input
                    @click="source = !source"
                    :value="!source"
                    :checked="!source"
                    id="asd"
                    type="checkbox"
                    name="Checkboxes5"
                  />
                  <span></span>
                  Kassa
                </label>
                <label class="checkbox checkbox-success">
                  <input
                    v-model="source1"
                    id="asd"
                    type="checkbox"
                    name="Checkboxes5"
                  />
                  <span></span>
                  Tortish
                </label>
              </div>
            </div>
          </div>
          <div class="card-header align-items-start">
            <div class="col-6 pl-0">
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="mr-3"
                    outlined
                    dense
                    :value="date | dateFilter"
                    label="Sana"
                    append-icon="event"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn small color="error" @click="modal = false"
                    >Bekor qilsih</v-btn
                  >
                  <v-btn small color="success" @click="$refs.dialog.save(date)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-dialog>
            </div>
            <div class="col-6 pr-0">
              <v-autocomplete
                v-model="transfer"
                :items="transfersList"
                item-value="id"
                class="mr-3"
                item-text="name"
                outlined
                dense
                return-object
                label="Vipiska Tranzaksiyalar"
              ></v-autocomplete>
            </div>
          </div>
          <div class="card-body">
            <div>
              <v-data-table
                v-model="selected"
                :headers="headers"
                dense
                :loading="isLoading"
                :items="list"
                disable-pagination
                hide-default-footer
              >
                <template v-slot:[`item.name`]="{ item }">
                  <div v-if="item.staffpersonal_description !== undefined">
                    {{ item.staffpersonal_description.first_name }}
                    {{ item.staffpersonal_description.last_name }}
                    {{ item.staffpersonal_description.father_name }}
                  </div>
                </template>
                <template v-slot:[`item.staffsalary_starting_saldo`]="{ item }">
                  <div v-if="item.transfer == undefined">
                    <div class="text-black" v-if="source1">
                      <input
                        v-currency="options"
                        type="text"
                        v-model="item.staffsalary_starting_saldo"
                      />
                    </div>
                    <div class="text-black" v-if="!source1">
                      <input
                        v-currency="options"
                        type="text"
                        v-model="item.staffpersonal"
                      />
                    </div>
                  </div>
                </template>
              </v-data-table>
            </div>
            <div class="total_result">
              <div class="mr-1">Umumiy summa - {{ totalSumma | maskZH }}</div>
              <div class="mr-1">
                Tranzaksiya - {{ transfer.amount | maskZH }}
              </div>
              <div
                style="background-color: #db5a5a"
                v-if="totalSumma - transfer.amount < 0"
              >
                Farki - ({{ (totalSumma - transfer.amount) | maskZH }} )
              </div>
              <div
                style="background-color: #c9f7f5"
                v-if="totalSumma - transfer.amount >= 0"
              >
                Farki - ({{ (totalSumma - transfer.amount) | maskZH }} )
              </div>
            </div>
            <div class="mt-5 d-flex justify-content-between">
              <div class="mr-2">
                <!-- {{ totalSumma | mask }} -->
                <v-btn
                  color="success"
                  @click="sendDdata"
                  :disabled="date == '' || newSalarystatmentLoading"
                >
                  <i
                    v-if="newSalarystatmentLoading"
                    class="el-icon-loading"
                  ></i>
                  Saqlash</v-btn
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Swal from 'sweetalert2'
export default {
  data: () => ({
    newSalarystatmentLoading: false,
    totalSumma: 0,
    date: '',
    compare_total: '',
    modal: false,
    transfer: '',
    source: true,
    source1: false,
    checkbox: false,
    selected: [],
    headers: [
      { text: '#', align: 'start', value: 'index' },
      { text: '№', value: 'tabel_number' },
      { text: 'Ф.И.О', value: 'name' },
      { text: 'Summa', value: 'staffsalary_starting_saldo' }
    ],
    selectedCurrencyOption: 1,
    currencyCode: 'USD',
    distractionFree: false,
    hideCurrencySymbol: true,
    hideGroupingSymbol: true,
    hideNegligibleDecimalDigits: true,
    prefix: null,
    suffix: null,
    precisionEnabled: true,
    precisionRangeEnabled: false,
    precisionFixed: 0,
    precisionRange: [0, 20],
    valueRangeEnabled: false,
    valueRange: [0, 9999]
  }),
  beforeCreate() {
    this.$store.dispatch('getStaffEmployedList')
    this.$store.dispatch('getPaymentSource')
  },
  computed: {
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    list() {
      var data = []
      this.$store.state.requests.staffEmployedList.forEach((element) => {
        // eslint-disable-next-line no-extra-boolean-cast
        if (
          element.staffsalary_starting_saldo !== undefined &&
          element.staffsalary_starting_saldo !== null
        ) {
          element.staffsalary_starting_saldo =
            element.staffsalary_starting_saldo.toLocaleString('es-US')
          if (typeof element.staffpersonal !== 'string')
            element.staffpersonal = 0
          data.push(element)
        } else {
          element.staffsalary_starting_saldo = '0'
          data.push(element)
        }
      })
      return data
    },
    sources() {
      const list = this.$store.state.requests.paymentSource
      return list
    },
    transfersList() {
      const list = this.$store.state.requests.transferSalaryList
      list.forEach((x) => {
        x.name =
          x.type_statetransfer_name +
          ' summa: ' +
          x.amount.toLocaleString('zh-ZH') +
          ' | ' +
          ' Izoh: ' +
          x.comment
      })
      return list
    },
    options() {
      return {
        locale: 'es-US',
        currency: [
          this.currencyCode,
          null,
          { prefix: this.prefix, suffix: this.suffix }
        ][this.selectedCurrencyOption],
        valueRange: this.valueRangeEnabled
          ? { min: this.valueRange[0], max: this.valueRange[1] }
          : undefined,
        precision: this.precisionEnabled
          ? this.precisionRangeEnabled
            ? { min: this.precisionRange[0], max: this.precisionRange[1] }
            : this.precisionFixed
          : undefined,
        distractionFree: this.distractionFree
          ? {
              hideNegligibleDecimalDigits: this.hideNegligibleDecimalDigits,
              hideCurrencySymbol: this.hideCurrencySymbol,
              hideGroupingSymbol: this.hideGroupingSymbol
            }
          : false,
        autoDecimalMode: false,
        valueAsInteger: false,
        allowNegative: false
      }
    }
  },
  filters: {
    mask(val) {
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(val)) return val.toLocaleString('es-US')
      else return 0
    },
    maskZH(val) {
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(val)) return val.toLocaleString('zh-ZH')
      else return 0
    }
  },
  watch: {
    list: function (val) {
      this.calc(val)
      console.log(val.totalSumma)
    },
    source1: function () {
      this.calc(this.list)
    },
    sources: function (val) {
      const data = {
        type_transfer: 'salary',
        payment_source: this.source ? val[0].id : val[1].id
      }
      this.$store.dispatch('getTransferSalaryList', data)
    },
    source: function (val) {
      const data = {
        type_transfer: 'salary',
        payment_source: val ? this.sources[0].id : this.sources[1].id
      }
      this.$store.dispatch('getTransferSalaryList', data)
    }
  },
  methods: {
    calc(val) {
      //eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(val)) {
        this.totalSumma = 0
        for (let index = 0; index < val.length; index++) {
          if (this.source1) {
            this.totalSumma += this.toFloat(
              val[index].staffsalary_starting_saldo
            )
          } else {
            this.totalSumma += this.toFloat(val[index].staffpersonal)
          }
        }
      }
    },
    toFloat(val) {
      if (typeof val == 'string' && Boolean(val)) {
        return parseFloat(val.replace(/[ ,]/g, ''), 10)
      } else return 0
    },
    sendDdata() {
      if (this.transfer !== null) {
        if (this.transfer.amount !== undefined) {
          if (this.totalSumma == this.transfer.amount) {
            this.transfer = this.transfer.id
          } else {
            this.transfer = null
          }
        } else {
          this.transfer = null
        }
      }
      if (this.source1) {
        const data = {
          paymentstatement: {
            payment_source: this.source
              ? this.sources[0].id
              : this.sources[1].id,
            transaction: this.transfer,
            oper_date: this.date
          },
          paymentstatement_elements: []
        }
        for (let index = 0; index < this.list.length; index++) {
          const data2 = {
            tabel_number: this.list[index].tabel_number,
            staffemployed_id: this.list[index].id,
            amount: this.toFloat(this.list[index].staffsalary_starting_saldo)
          }
          if (data2.amount !== 0) data.paymentstatement_elements.push(data2)
        }
        console.log('data', data)
        this.newSalarystatmentLoading = true
        this.$store
          .dispatch('createPaymentStatements', data)
          .then(() => {
            this.newSalarystatmentLoading = false
          })
          .catch((err) => {
            this.newSalarystatmentLoading = false
            console.error(err)
          })
      } else {
        const data = {
          paymentstatement: {
            payment_source: this.source
              ? this.sources[0].id
              : this.sources[1].id,
            transaction: this.transfer,
            oper_date: this.date
          },
          paymentstatement_elements: []
        }
        for (let index = 0; index < this.list.length; index++) {
          console.log('22', this.list[index].staffpersonal)
          const data2 = {
            tabel_number: this.list[index].tabel_number,
            staffemployed_id: this.list[index].id,
            amount: this.toFloat(this.list[index].staffpersonal)
          }
          if (data2.amount !== 0) data.paymentstatement_elements.push(data2)
        }
        console.log('data', data)
        this.newSalarystatmentLoading = true
        this.$store
          .dispatch('createPaymentStatements', data)
          .then(() => {
            this.newSalarystatmentLoading = false
          })
          .catch((err) => {
            this.newSalarystatmentLoading = false
            console.error(err)
          })
      }
    }
  }
}
</script>
<style scoped>
.card-header {
  padding-top: 1.5rem !important;
}
.w-5em {
  width: 5em;
}
.card-body-table {
  overflow-x: scroll;
}
p {
  margin-bottom: 0;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  text-align: center;
}
table td {
  text-align: center;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 5px !important;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  /* font-size: 1rem !important; */
  /* color: #000 !important; */
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  /* padding: 0 !important; */
  height: 45px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0 !important;
}
.total_result {
  display: flex;
  margin: 30px 0;
}
.total_result div {
  flex: 1;
  background-color: #c9f7f5;
  padding: 12px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: bold;
  color: #1bc5bd;
}
.total_result div:nth-child(2) {
  background-color: #c9f7f5;
  color: #5868ddc1;
}
.total_result div:nth-child(3) {
  color: #333;
}
</style>
